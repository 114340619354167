import { Redirect } from "@reach/router"
import React, { FunctionComponent } from "react"
import { useQuery } from "react-query"
import { getCurrentUser } from "@utils/auth"

//TODO: I don't think we are using this login/logout page anymore. Perhaps we can remove this file?
const Logout: FunctionComponent<any> = (props) => {
  const prevPath = props?.location?.state?.prevPath || "/"
  const {
    isLoading,
    error,
    data: user
  } = useQuery("logout", () => getCurrentUser())

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error || !user) {
    return <Redirect noThrow to={prevPath} from="/logout" />
  }

  return <div>Logging out...</div>
}

export default Logout
